import { filteredGetBuilder } from "./response";

export interface NewsletterSubscriptionDto {
  id: number;
  email: string;
  created: string | Date;
  modified?: string | Date | null;
  active: number | boolean;
}

export const list = filteredGetBuilder<NewsletterSubscriptionDto>(`backend/newsletter-subscriptions`);

export const listForEmail = (email: string) => {
  return list({ email }, { itemsPerPage: 10, page: 1, sortBy: [], sortDesc: [] });
}

export default {
  list,
  listForEmail,
}