import { parsePhoneNumber } from "awesome-phonenumber";

export function acronym(input) {
  if (!input) return null;

  if (input.includes('@')) {
    return `@${input.split("@")[0]}`;
  }

  return input
    .split(" ")
    .map((i) => i.charAt(0).toUpperCase())
    .join("");
}

export function firstLetterUpperAll(sentence) {
  return sentence
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
    .join(" ")
    .split("-")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join("-");
}

export function formatCurrency(input, minPrecision, maxPrecision) {
  if (input === undefined || input === null || isNaN(input) || input === '') return "";
  minPrecision = minPrecision >= 0 ? parseInt(minPrecision) : 2;
  maxPrecision = maxPrecision >= 0 ? parseInt(maxPrecision) : 2;
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }).format(input);
}

export function formatDecimal(input, minPrecision, maxPrecision) {
  if (input === undefined || input === null || isNaN(input)) return "";
  minPrecision = minPrecision >= 0 ? parseInt(minPrecision) : 2;
  maxPrecision = maxPrecision >= 0 ? parseInt(maxPrecision) : minPrecision;
  return new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }).format(input);
}

export function formatGtin(input) {
  let gtin = input.toString();
  if (gtin.length == 13) {
    return gtin.replace(/(\d{1})(\d{6})(\d{6})/, "$1 $2 $3");
  }
  return gtin;
}

export function formatEan(input) {
  let ean = input.toString();
  if (ean.length == 13) {
    return ean.replace(/(\d{1})(\d{6})(\d{6})/, "$1 $2 $3");
  }
  return ean;
}

export function formatInt(input) {
  if (!input || isNaN(input) || Math.round(input) == 0) return "";
  return new Intl.NumberFormat("fr-FR").format(Math.round(input));
}

export function formatIntTo36(input) {
  if (!input || isNaN(input) || Math.round(input) == 0) return "";
  return parseInt(input).toString(36).toUpperCase();
}

export function formatLegalId(input, countryId) {
  if (!countryId) return input;
  if (!input) return null;

  input = String(input);

  if (countryId == 250) {
    if (input.length === 14) {
      return input.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, "$1 $2 $3 $4");
    } else if (input.length === 9) {
      return input.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
    }
  }
  return input;
}

export function formatSize(input) {
  const i = Math.floor(Math.log(input) / Math.log(1024));

  if (i === 0) {
    return input + " b";
  }

  if (i === 1) {
    return (input / Math.pow(1024, i)).toFixed(0) * 1 + " " + ["o", "Ko", "Mo", "Go", "To"][i];
  }
  return (input / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["o", "Ko", "Mo", "Go", "To"][i];
}

export function formatTaxId(input, countryId) {
  if (!countryId) return input;
  if (!input) return null;

  input = String(input);

  if (countryId == 250) {
    if (input.length === 13) {
      return input.replace(/(\w{4})(\d{3})(\d{3})(\d{3})/, "$1 $2 $3 $4");
    }
  }
  return input;
}

export function ibanFormat(iban) {
  return splitString(iban, 4);
}

/**
 * Validate Iban
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be
 * (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid
 * (check digits do not match)
 */
export function ibanValidate(input) {
  const countryLengths = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
  };

  // convert to uppercase and remove spaces
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, "");

  // match and capture (1) the country code, (2) the check digits, and (3) the rest
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

  // check syntax and length
  if (!code || iban.length !== countryLengths[code[1]]) {
    return false;
  }

  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => letter.charCodeAt(0) - 55);

  return mod97(digits) === 1;
}

function mod97(string) {
  let checksum = string.slice(0, 2);
  const length = string.length;
  let fragment = null;

  for (let offset = 2; offset < length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export function phoneFormat(input) {
  let phn = parsePhoneNumber(input);
  if (phn.possibility === 'invalid-country-code') {
    phn = parsePhoneNumber(input, { regionCode: 'FR' });
  }
  if (phn.valid) {
    return phn.number.international;
  }
  return input;
}

export const nl2br = (s) => {
  if (typeof s === "undefined" || s === null || !s.toString().trim()) {
    return "";
  }

  return s.toString().replace(/(?:\r\n|\r|\n)/g, "<br>");
};

export function splitString(str, size) {
  if (size < 1 || str == null) {
    return null;
  }

  const chunks = [];
  const charsLength = str.length;

  for (let i = 0; i < charsLength; i += size) {
    chunks.push(str.substring(i, i + size));
  }

  return chunks.join(" ");
}

/**
 * Parse a string to a number
 *
 * @param {String} s
 * @param {Object} options
 * @returns {String or Int or Float}
 */
export function strToNumber(s, options) {
  let n = 0;
  const regex = new RegExp("/[^0-9.\\-\\+\\*\\/\\(\\)]/", "g");

  if (s === "") {
    n = 0;
  } else if (isNaN(s)) {
    s = s.replace(/[^0-9.,\\-]/g, "");
    s = s.replace(",", ".");

    if (typeof options.calculate !== "undefined" && options.calculate) {
      s = String(eval(s.replace(regex, "")));
    }

    if (typeof options.negative !== "undefined" && !options.negative) {
      n = s.replace(/[^0-9.]/g, "");
    } else {
      n = s.replace(/[^0-9.\\-]/g, "");
    }
    if (n === "") n = 0;
  } else {
    n = s;
  }

  if (n === 0) {
    return typeof options.empty !== "undefined" ? options.empty : "";
  }

  if (typeof options.type !== "undefined" && options.type === "integer") {
    return parseInt(n);
  }

  if (typeof options.type !== "undefined" && options.type === "float") {
    if (typeof options.decimal !== "undefined") {
      return parseFloat(n).toFixed(options.decimal);
    }

    return parseFloat(n);
  }

  return n;
}

export function typeLabel(journalType, typeList) {
  let label = null;
  typeList.some((type) => {
    if (type.value == journalType) {
      label = type.text;
    }
  });
  return label;
}

export function validateAccount(input) {
  return String(input).length <= 11;
}

export function validateEmail(input) {
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(input);
}

export function validateFilename(input) {
  const reg = /^(?:[0-9a-z._-]+$)/;
  return reg.test(input);
}

export function validateGtin(input) {
  const s = input.toString().trim();

  switch (s.length) {
    case 8:
    case 12:
    case 13:
      return s.slice(-1) === checkDigitGTIN13(s.slice(0, -1));
    case 14:
      return s.slice(-1) === checkDigitGTIN14(s.slice(0, -1));
    default:
      return false;
  }
}

export function validateInt(input) {
  const reg = /^(?:[0-9]+$)/;
  return reg.test(input);
}

export function validateLengthMax(input, length) {
  return String(input).length <= length;
}

export function validateLengthMin(input, length) {
  return String(input).length >= length;
}

export function validateNumber(input) {
  const reg = /^[-+]?(?:[0-9.]+$)/;
  return reg.test(input);
}

export function validateNumberPositive(input) {
  const reg = /^(?:[0-9.]+$)/;
  return reg.test(input);
}

export function validateNumberAndSpace(input) {
  const reg = /^(?:[0-9 ]+$)/;
  return reg.test(input);
}

export function validatePhone(input) {
  const reg = /^(?:[0-9 +]+$)/;
  return reg.test(input);
}

// 🤮 A supprimer et nettoyer les données des WS
export function zipCodeConvert(zipCode, countryId) {
  if (!zipCode) return null;
  if (!countryId) return zipCode;
  if (parseInt(zipCode) < 10000 && (countryId == 250 || countryId == "FR")) {
    return "0" + zipCode.toString();
  }
  return zipCode;
}

function checkDigitGTIN13(barcode) {
  let sum = 0;
  for (let i = barcode.length - 1; i >= 0; i--) {
    sum = sum + parseInt(barcode.charAt(i)) * (1 + 2 * (i % 2));
  }
  return ((10 - (sum % 10)) % 10).toString();
}

function checkDigitGTIN14(barcode) {
  let sum = 0;
  for (let i = barcode.length - 1; i >= 0; i--) {
    sum = sum + parseInt(barcode.charAt(i)) * (1 + 2 * ((i + 1) % 2));
  }
  return ((10 - (sum % 10)) % 10).toString();
}
