
import { filteredGetBuilder } from './response';

export interface JournalDto {
  id: number;
  editable: boolean | number;
  code: string;
  status: number;
  type: number;
  label: string;
  account_id: string | number | null;
  contact_id: string | number | null;
  iban_id: string | number | null;
}

export const list = filteredGetBuilder<JournalDto>(`backend/journals`);

export default {
  list,
}