import { filteredGetBuilder } from "./response";


export enum DocHistoryType {
  DocFrom = 1,
  Payment = 2,
  DocTo = 3,
  Mail = 4,
  // ['value' => 5, 'text' => "Files : upload"],
  // ['value' => 6, 'text' => "Files : delete"],
  PhoneOutgoing = 7,
  // ['value' => 8, 'text' => "Transaction"],
  // ['value' => 9, 'text' => "Files : downloaded"],
  OrderFrom = 10,
  PhoneIncoming = 11,
  PaymentFailed = 12,
  PaymentAbandoned = 13,
}

export const docHistoryTypeList = [
  DocHistoryType.DocFrom,
  DocHistoryType.Payment,
  DocHistoryType.DocTo,
  DocHistoryType.Mail,
  DocHistoryType.PhoneOutgoing,
  DocHistoryType.OrderFrom,
  DocHistoryType.PhoneIncoming,
  DocHistoryType.PaymentFailed,
  DocHistoryType.PaymentAbandoned,
]

export interface DocHistoryDto {
  id: number;
  doc_id?: number | null;
  request_id?: number | null;
  writer_id?: number | null;
  date: string | Date | null;
  status: number; // TODO
  ref_doc_id: number | null;
  type?: DocHistoryType | null;
  label: string | null;
  description: string | null;
  read_date: string | Date | null;
  uuid: string | null;
  writer_name: string | null;
}

export const list = filteredGetBuilder<DocHistoryDto>(`backend/dochistories`);

export default {
  list,
}
