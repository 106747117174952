
import { DocDto, DocTypeEnum } from '@/types/doc';
import { ShipmentLineDto } from '@/types/shipment';
import { elementGet, filteredGetEnhancedBuilder } from './response';

export const docShipmentLines = async (docId: number | string, shipmentId: number | string) => elementGet<ShipmentLineDto[]>(`backend/docs/${docId}/shipment-lines`, { searchParams: { shipment_id: shipmentId }});
export const list = filteredGetEnhancedBuilder<DocDto, { type: DocTypeEnum }>(`backend/docs`);
export const listPurchaseDue = filteredGetEnhancedBuilder<DocDto, { type: DocTypeEnum }>(`backend/purchase/due`);

export default {
  docShipmentLines,
  list,
  listPurchaseDue,
}