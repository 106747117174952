
import { elementGet, filteredGetBuilder } from './response';

export interface MediaDto {
  id: number;
  alt: string;
  name: string;
  height: number;
  width: number;
  modified: string | Date;
}

export const list = filteredGetBuilder<MediaDto>(`backend/medias`);

export const get = async (id: number | string) => elementGet<MediaDto>(`backend/medias/${id}`);

export default {
  list,
  get,
}