
import { filteredGetBuilder } from './response';

export interface BusinessActivityDto {
  id: number;
  country_id: number | null;
  code: string;
  label: string;
}

export const list = filteredGetBuilder<BusinessActivityDto>(`backend/businessactivities`);

export default {
  list,
};