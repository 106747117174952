import { filteredGetBuilder } from "./response";

export interface VatRateDto {
  id: number;
  valeur: number;
}

export const list = filteredGetBuilder<VatRateDto>(`backend/tvas`);

export default {
  list,
}