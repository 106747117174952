import { filteredGetBuilder } from "./response";

interface ContactHistoryDto {
  id: number;
  from_id: number;
  to_id: number | null;
  to_email: string | null;
  contact_id: number | null;
  doc_id: number | null;
  doc_type: number | null;
  status: 1 | 0;
  date: string | Date;
  subject: string;
  message: string;
  read_date: string | Date | null;
  uuid: string | null;
  from_name: string;
  from_type: number;
  to_name: string | null;
  to_type: number | null;
  contact_name: string;
  doc_doc_id: number | null;
}

export const list = filteredGetBuilder<ContactHistoryDto>(`backend/contacthistories`);

export default {
  list,
};