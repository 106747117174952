import { LaPosteResponseDto } from "@/types/laposte";
import { elementGet, filteredGetBuilder } from "./response";
import { ShipmentDto } from "@/types/shipment";
import { apiHttp } from "../api-http";

// TODO : Réponse générique
export const tracking = async (carrier: string, trackingNumber: number | string) => elementGet<LaPosteResponseDto>(`backend/shipments/tracking/${carrier}/${trackingNumber}`);

export const list = filteredGetBuilder<ShipmentDto>(`backend/shipments`);
export const remove = (id: number | string) => apiHttp.delete(`backend/shipments/${id}`);

export default {
  tracking,
  list,
  remove,
}