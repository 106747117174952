import { elementGet } from "./response";

export interface TransactionVatReportEntryDto {
  account_id: number;
  label: string;
  amount: string;
}

export interface TransactionVatReportDto {
  year: string;
  month: string;
  entries: TransactionVatReportEntryDto[];
}

export const vatReport = async (yearMonth: string) => elementGet<TransactionVatReportDto>(`backend/transactions/vat-report/${yearMonth}`);

export default {
  vatReport,
}