
import { Response } from '.';
import { apiHttp } from '../api-http';

export interface FileDto {
  date: string;
  ext: string;
  id: string;
  name: string;
  size: number;
}

export const list = async (folder: string | number, key: string | number) => {
  const res = await apiHttp.get(`backend/files/list/${folder}/${key}`, { timeout: 15000 }).json<Response<FileDto[]>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
}

export default {
  list,
}
