import accounts from './accounts';
import businessActivities from './business-activities';
import categories from './categories';
import conditionnements from './conditionnements';
import contactHistories from './contact-histories';
import contactMetas from './contact-meta';
import contacts from './contacts';
import countries from './countries';
import currencies from './currencies';
import docOverrides from './doc-overrides';
import docHistories from './doc-histories';
import docs from './docs';
import emailtemplates from './email-templates';
import files from './files';
import flux from './flux';
import journals from './journals';
import legals from './legals';
import matrices from './matrices';
import me from './me';
import medias from './medias';
import newsletterSubscriptions from './newsletter-subscriptions';
import organizationTypes from './organization-types';
import params from './params';
import payments from './payments';
import productReviews from './product-reviews';
import products from './products';
import requestQuotes from './request-quotes';
import salesStats from './sales-stats';
import shipments from './shipments';
import siteReviews from './site-reviews';
import tasks from './tasks';
import transactions from './transactions';
import vatRates from './vat-rates';

export type { Error, Response } from './response';

export const apiPdg = {
  accounts,
  businessActivities,
  categories,
  conditionnements,
  contactHistories,
  contactMetas,
  contacts,
  countries,
  currencies,
  docOverrides,
  docHistories,
  docs,
  emailtemplates,
  files,
  flux,
  journals,
  legals,
  matrices,
  me,
  medias,
  newsletterSubscriptions,
  organizationTypes,
  params,
  payments,
  productReviews,
  products,
  requestQuotes,
  salesStats,
  shipments,
  siteReviews,
  tasks,
  transactions,
  vatRates,
}