
import { elementGet } from './response';

export interface FluxCheckDto {
  missing_shipping: number[];
  missing_zip_code: number[];
  missing_description: number[];
  shipping_truncated: number[];
  exported: number;
  count: number;
}

export const check = async (id: number | string) => elementGet<FluxCheckDto>(`backend/marketing/flux/${id}`, { searchParams: { check: true }, timeout: false });

export default {
  check,
}