import { elementGet, filteredGetBuilder } from "./response";

export const ContactMetaKeyEnum = {
  PAYMENT_DELAY_ORDER: "PAYMENT_DELAY_ORDER",
  PAYMENT_DELAY_INVOICE: "PAYMENT_DELAY_INVOICE",
  PAYMENT_DELAY_MODE: "PAYMENT_DELAY_MODE",
  ESCOMPTE: "ESCOMPTE",
  DISPUTE: "DISPUTE",
  VAT_RATE: "VAT_RATE",
  COMMENTAIRE: "Commentaire",
};

export const ContactMetaValueEnum = {
  PAYMENT_DELAY_MODE_FDM: "FDM",
  PAYMENT_DELAY_MODE_FDM15: "FDM15",
};

export interface ContactMetaDto {
  id: number;
  contact_id: number;
  mkey: string;
  mvalue: string;
}

export const list = filteredGetBuilder<ContactMetaDto>(`backend/contactmetas`);

export const listForContact = (contactId: number) => {
  return list({ contact_id: contactId }, { itemsPerPage: 10, page: 1, sortBy: [], sortDesc: [] });
}

export const getForContact = async (contactId: number, key: string) => {
  return (await listForContact(contactId)).data.find(val => val.mkey === key) ?? null;
}

export const keys = async () => elementGet<string[]>(`backend/contactmetas/keys`);

export default {
  list,
  listForContact,
  getForContact,
  keys,
}