
import { filteredGetBuilder } from './response';

export interface ParamsDisplayOutput {
  text: string;
  value: string;
}

export interface ParamsDto {
  id: number;
  type: string;
  key: string;
  sort: number | null;
  value: string;
}

export const list = filteredGetBuilder<ParamsDto | ParamsDisplayOutput>(`backend/params`);

export default {
  list,
}
