
import { filteredGetBuilder } from './response';

export interface CategoryDto {
  id: number;
  parent_id: number | null;
  name: string;
  slug: string;
  description: string | null;
  meta_title: string | null;
  meta_description: string | null;
  meta_keywords: string | null;
  created: string | Date;
  modified: string | Date;
  classement: number | null;
  tree: string;
  hidden: boolean | 1 | 0;
  promote: boolean | 1 | 0;
  homepage: boolean | 1 | 0;
  hide_facets: boolean | 1 | 0;
  parent: string | null;
}

export const list = filteredGetBuilder<CategoryDto>(`backend/categories`);

export default {
  list,
};