import { mdiAlarmOff, mdiArchiveArrowDown, mdiCircleOutline, mdiCircleSlice2, mdiCircleSlice4, mdiCircleSlice8, mdiCurrencyEurOff } from "@mdi/js";
import { ProductShippingAvailability } from "./products";
import { elementGet, filteredGetEnhancedBuilder } from './response';

export const RequestQuoteStatusEnum = {
  OPEN: 0,
  WAITING_SUPPLIER: 1,
  WAITING_CUSTOMER: 2,
  CLOSED: 3,
  ARCHIVED: 4,
  WAITING_PAYMENT: 512,
  EXPIRED: 4096,
};

export interface RequestQuoteDto {
  id: number | null;
  public_uuid: string | null;
  user_id: number | null;
  contact_name: string | null;
  cart_id: number | null;
  created: string | null;
  modified: string | null;
  reminder_date: string | null;
  message: string | null;
  cp: string | null;
  shipping_address: string | null;
  expiry_date: string | null;
  shipping_constraints: string | null;
  shipping_delay: string | null;
  traite: number | null;
  commercial_id: number | null;
  commercial_name: string | null;
  // shipping_address_zip_code: string;
  // shipping_address_city: string;
  shipping_type_id: number | null;
  // date: string;
  // date_due: string;
  total_before_tax: number | null;
  total_tax: number | null;
  total_with_tax: number | null;
  total_eco_part: number | null;
  // request_review: boolean | number;
  // from_contact_id: number;
  // from_contact_name: string;
  // from_logo: number | boolean;
  from_address: string | null;
  from_info: string | null;
  shipping_contact_id: number | null;
  // shipping_address: number;
  shipping_info: number | null;
  shipping_city: string | null;
  // // shipping_contact_name: number;
  // to_contact_id: number;
  // to_contact_name: string;
  // to_address: string;
  // status: number;
  // date_created: string;
  // payment_label: string;
  label: string | null;
  // discount_value: number;
  // total: number;
  has_utms?: boolean;
  utms?: string;
  automation_subject?: string;
  automation_date?: string;
}

export interface RequestQuoteProductDto {
  id: number,
  request_id: number | null,
  product_id: number | null,
  label: string | null,
  price: number | null,
  price_supplier: number | null,
  quantity: number | null,
  supplier_id: number | null,
  tax: number | null,
  eco_part: number | null,
  total_eco_part: number | null,
  unavailable: boolean | number | null,
  alternative: boolean | number | null,
  tailormade: boolean | number | null,
  display_order: number | null,

  name: string | null, // deprecated ?
  price_sell: number | null,
  price_buy: number | null,
  reference: string | null,
  supplier_name: string | null,
  brand_item_reference: string | null,
  line_supplier_id: number | null,
  line_supplier_name: string | null,
  image: string | null,
  packaging_type: string | null,
  packaging_unit: string | null,
  packaging_quantity: number | null,
  parent_id: number | null;
  is_kit: number | boolean | null;
  degressive_shipping_costs: number | null;
  price_sell_modified: string | Date | null;
  price_buy_modified: string | Date | null;
}

export interface RequestQuoteStatsDto {
  day: number,
  month: number,
  ytd: number,
  year: number,
  dayAverage: number,
  monthAverage: number,
}

export interface RequestQuoteShippingDto {
  products_shipping_availability: { [key: number /* product id */]: ProductShippingAvailability };
  products_delivery_time: { [key: number /* product id */]: number /* nombre de jours */ };
  shipping_price: {
    has_degressive_shipping_costs: boolean;
    purchase: number | null;
    sale: number | null;
  }
}

export function requestQuoteStatusIcon(/* RequestQuoteStatusEnum */ status: number | null) {
  switch (status) {
    case RequestQuoteStatusEnum.WAITING_SUPPLIER: return mdiCircleSlice2;
    case RequestQuoteStatusEnum.WAITING_CUSTOMER: return mdiCircleSlice4;
    case RequestQuoteStatusEnum.CLOSED: return mdiCircleSlice8;
    case RequestQuoteStatusEnum.ARCHIVED: return mdiArchiveArrowDown;
    case RequestQuoteStatusEnum.WAITING_PAYMENT: return mdiCurrencyEurOff;
    case RequestQuoteStatusEnum.EXPIRED: return mdiAlarmOff;
    case RequestQuoteStatusEnum.OPEN:
    default:
      return mdiCircleOutline;
  }
}

export function requestQuoteStatusColor(/* RequestQuoteStatusEnum */ status: number | null) {
  switch (status) {
    case RequestQuoteStatusEnum.WAITING_SUPPLIER: return "orange--text";
    case RequestQuoteStatusEnum.WAITING_CUSTOMER: return "blue--text";
    case RequestQuoteStatusEnum.CLOSED: return "green--text";
    case RequestQuoteStatusEnum.ARCHIVED: return "";
    case RequestQuoteStatusEnum.WAITING_PAYMENT: return "orange--text";
    case RequestQuoteStatusEnum.EXPIRED: return "red--text";
    case RequestQuoteStatusEnum.OPEN:
    default:
      return "";
  }
}

export function requestQuoteStatusTitle(/* RequestQuoteStatusEnum */ status: number | null) {
  switch (status) {
    case RequestQuoteStatusEnum.WAITING_SUPPLIER: return "Waiting supplier";
    case RequestQuoteStatusEnum.WAITING_CUSTOMER: return "Waiting customer";
    case RequestQuoteStatusEnum.CLOSED: return "Closed";
    case RequestQuoteStatusEnum.ARCHIVED: return "Archived";
    case RequestQuoteStatusEnum.WAITING_PAYMENT: return "Waiting payment";
    case RequestQuoteStatusEnum.EXPIRED: return "Expired";
    case RequestQuoteStatusEnum.OPEN:
    default:
      return "Open";
  }
}

export const list = filteredGetEnhancedBuilder<RequestQuoteDto, { stats: RequestQuoteStatsDto | undefined }>(`backend/requestquotes`);

export const get = async (id: number) => elementGet<{ request: RequestQuoteDto, lines: Partial<RequestQuoteProductDto>[] }>(`backend/requestquotes/${id}`);

export const shipping = async (id: number) => elementGet<RequestQuoteShippingDto>(`backend/requestquotes/${id}/shipping`);

export default {
  list,
  get,
  shipping,
}