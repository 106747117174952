import { filteredGetBuilder } from "./response";

export interface MatriceDto {
  id: number;
  created: Date | string | null;
  modified: Date | string | null;
  name: string;
}

export const list = filteredGetBuilder<MatriceDto>(`backend/matrices`);

export default {
  list,
};