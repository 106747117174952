import { filteredGetBuilder } from "./response";

export interface CurrencyDto {
  id: number;
  iso_code: number;
  status: number;
  sign: string;
  code: string;
  currency_l: string;
  currency: string;
}

export const list = filteredGetBuilder<CurrencyDto>(`backend/currencies`);

export default {
  list,
};